import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Header from './components/nav-header';
import LandingPage from './components/landing-page/landing-page.component';

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <LandingPage/>
        </BrowserRouter>
    );
}

export default App;
