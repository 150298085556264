import React from "react";
import Button from "../button";
import {useContactModal} from "../modals/contact-context";

export default function CtaContact() {
    const { setContactModal } = useContactModal()

    return (
        <div className="bg-tertiary-700">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
                <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                    <span className="block">Sie brauchen Hilfe bei Ihrem IT-Vorhaben?</span>
                    <span className="block text-primary-500">Sichern Sie sich ein kostenloses und unverbindliches Beratungsgespräch.</span>
                </h2>
                <div className="mt-8 flex">
                    <div className="inline-flex rounded-md shadow">
                        <Button
                            key="name"
                            value="value"
                            variant="primary"
                            disabled= {false}
                            onClick={() => {setContactModal(true)}}
                        >
                            Kontakt aufnehmen
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}