import React from 'react';

import LandingHero from "./landing-hero/";
import FeatureSection from "./feature-section/";
import LogoCloudSection from "./logo-cloud-section";
import Footer from "../footer";
import CtaContact from "./cta-contact";

const LandingPage = () => {
    return (
        <main className="bg-tertiary-500 lg:relative w-full">
            <LandingHero/>
            {/*<LogoCloudSection/>*/}
            <FeatureSection/>
            <CtaContact/>
            <Footer/>
        </main>
    )
}

export default LandingPage;