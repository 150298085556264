import React, {Fragment, useCallback, useEffect, useState} from 'react'
import emailjs from 'emailjs-com';
import {Dialog, Transition} from "@headlessui/react";
import {Form, Input, TextArea, Button} from 'semantic-ui-react';
import Swal from 'sweetalert2';


const ContactModalContext = React.createContext();
const SERVICE_ID = "service_kzyvi9f";
const TEMPLATE_ID = "template_l1sw5a6";
const USER_ID = "user_TKaJaqZpzNOFLgAqxt7IL";

const ContactModal = ({modal, unSetContactModal}) => {
    useEffect(() => {
        const bind = e => {
            if (e.keyCode !== 27) {
                return
            }

            if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
                return
            }

            unSetContactModal()
        }

        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
    }, [modal, unSetContactModal])

    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((result) => {
                console.log(result.text);
                unSetContactModal(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully',
                    background: '#001d3d',
                    color: '#fff',
                    iconColor: '#92d6f4',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'px-4 py-2 focus:outline-none transition ease-in-out duration-300 rounded w-full flex items-center justify-center border border-transparent bg-primary-500 hover:bg-primary-600 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 text-tertiary-500'
                    }
                })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, something went wrong',
                    text: error.text,
                })
            });
        e.target.reset()
    };

    return (
        <Transition.Root show={modal} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={unSetContactModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Kontakt aufnehmen
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className="App">
                                            <Form onSubmit={handleOnSubmit}>
                                                <Form.Field
                                                    id='form-input-control-email'
                                                    control={Input}
                                                    label='Email'
                                                    name='user_email'
                                                    placeholder='Email…'
                                                    required
                                                    icon='mail'
                                                    iconPosition='left'
                                                />
                                                <Form.Field
                                                    id='form-input-control-last-name'
                                                    control={Input}
                                                    label='Name'
                                                    name='user_name'
                                                    placeholder='Name…'
                                                    required
                                                    icon='user circle'
                                                    iconPosition='left'
                                                />
                                                <Form.Field
                                                    id='form-textarea-control-opinion'
                                                    control={TextArea}
                                                    label='Message'
                                                    name='message'
                                                    placeholder='Message…'
                                                    required
                                                />
                                                <Button type='submit' color='green'>Submit</Button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => unSetContactModal(false)}
                                >
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
const ContactModalProvider = props => {
    const [modal, setContactModal] = useState(false)
    const unSetContactModal = useCallback(() => {
        setContactModal()
    }, [setContactModal])


    return (
        <ContactModalContext.Provider value={{unSetContactModal, setContactModal}} {...props} >
            {props.children}
            {modal && <ContactModal modal={modal} unSetContactModal={unSetContactModal}/>}
        </ContactModalContext.Provider>
    )
}

const useContactModal = () => {
    const context = React.useContext(ContactModalContext)
    if (context === undefined) {
        throw new Error('useModal must be used within a UserProvider')
    }

    return context
}

export {ContactModalProvider, useContactModal}