import React from "react";

import {
    BriefcaseIcon,
    MinusIcon,
    CursorClickIcon,
    HeartIcon
} from '@heroicons/react/solid'

const steps = [
    {id: '1', name: 'Medizin-Informatik', status: 'current'},
    {id: '2', name: 'Unternehmens-IT', status: 'upcoming'},
    {id: '3', name: 'Frontend Entwicklung', status: 'complete'},
]

const StepBasic = "px-2 py-2 flex flex-col items-center justify-center text-sm font-medium ";
const roundStepBasic = "flex-shrink-0 w-7 h-7 flex items-center justify-center border-2 rounded-full border-transparent";

export default function Steps() {
    return (
        <nav aria-label="Progress" className="mb-5">
            <ol role="list" className="border-b-2 border-primary-300 flex divide-y-0">
                {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative flex-1 justify-center flex text-sm items-start">
                        {step.status === 'complete' ? (
                            <span className={StepBasic}>
                  <span
                      className={roundStepBasic}>
                    <CursorClickIcon className="w-5 h-5 text-primary-500" aria-hidden="true"/>
                  </span>
                  <span className="font-medium text-primary-500 text-xs">{step.name}</span>
                </span>
                        ) : step.status === 'current' ? (
                            <span
                                className={StepBasic}
                                aria-current="step">
                                <span
                                    className={roundStepBasic}>
                                        <HeartIcon className="w-5 h-5 text-primary-500" aria-hidden="true"/>
                                </span>
                                <span className="font-medium text-primary-500 text-xs font-bold">{step.name}</span>
                            </span>
                        ) : (
                            <span
                                className={StepBasic}>
                  <span
                      className={roundStepBasic}>
                    <BriefcaseIcon className="w-5 h-5 text-primary-500" aria-hidden="true"/>
                  </span>
                                <span
                                    className="font-medium text-primary-500 text-xs">{step.name}</span>
                            </span>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="hidden md:flex absolute top-0 right-0 h-full w-5 items-center"
                                     aria-hidden="true">
                                    <MinusIcon className="h-6 w-6 text-primary-500" aria-hidden="true"/>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}