import React from "react";
import {useImprintModal} from "../landing-page/modals/imprint-context";
import {useContactModal} from "../landing-page/modals/contact-context";
/* This example requires Tailwind CSS v2.0+ */
const navigation = {
    social: [
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/in/andré-sanchez-915857131/',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'XING',
            href: 'https://www.xing.com/profile/Andre_Sanchez5/cv',
            icon: (props) => (
                <svg fill="currentColor" width="24" height="24" viewBox="426.896 102.499 170.207 200">
                    <path
                        d="M442.394 142c-1.736 0-3.197.61-3.934 1.803-.76 1.231-.645 2.818.166 4.424l19.503 33.761c.033.064.033.105 0 .164l-30.648 54.084c-.799 1.592-.76 3.191 0 4.425.736 1.187 2.033 1.966 3.771 1.966h28.844c4.312 0 6.393-2.91 7.867-5.57l31.14-55.068c-.118-.19-19.83-34.58-19.83-34.58-1.439-2.557-3.606-5.41-8.03-5.41h-28.849z"/>
                    <path
                        d="M563.574 102.501c-4.309 0-6.176 2.714-7.723 5.494l-64.188 113.818 40.984 75.191c1.432 2.558 3.641 5.494 8.06 5.494h28.81c1.738 0 3.096-.654 3.828-1.843.77-1.23.748-2.857-.059-4.458l-40.664-74.295a.167.167 0 0 1 0-.189l63.863-112.92c.803-1.594.82-3.22.061-4.452-.736-1.188-2.098-1.843-3.836-1.843h-29.139v.002h.003z"/>
                </svg>
            ),
        },
        {
            name: 'GitHub',
            href: 'https://github.com/sanchez089',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}

export default function Footer() {
    const { setImprintModal } = useImprintModal()
    const { setContactModal } = useContactModal()

    return (
        <footer className="bg-tertiary-500">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <div className="mt-8 flex justify-center space-x-6">
                    {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} className="text-gray-100 hover:text-gray-300">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true"/>
                        </a>
                    ))}
                </div>
                <nav className="-mx-5 my-2 flex flex-wrap justify-center" aria-label="Footer">
                        <div key="Impressum" className="px-5 py-2">
                            <button className="text-base text-gray-300 hover:text-gray-400" onClick={() => {setImprintModal(true)}}>
                                Impressum
                            </button>
                        </div>
                    <div key="Kontakt" className="px-5 py-2">
                        <button className="text-base text-gray-300 hover:text-gray-400" onClick={() => {setContactModal(true)}}>
                            Kontakt
                        </button>
                    </div>
                </nav>
                <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 André Sanchez. All rights
                    reserved.</p>
            </div>
        </footer>
    )
}