import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import clear from '../../../alias/clear';

const classes = {
	base: 'focus:outline-none transition ease-in-out duration-300 rounded w-full flex items-center justify-center border border-transparent',
	disabled: 'opacity-10 cursor-not-allowed',
	size: {
		small: 'px-2 py-1 text-sm',
		normal: 'px-4 py-2',
		large: 'px-8 py-3 text-lg'
	},
	variant: {
		primary:
			'bg-primary-500 hover:bg-primary-600 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 text-tertiary-500',
		secondary:
			'bg-gray-200 hover:bg-gray-800 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-gray-900 hover:text-white',
		danger: 'bg-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white'
	}
};

const Button = forwardRef(
	(
		{
			children,
			type = 'button',
			variant = 'primary',
			size = 'normal',
			disabled = false,
			href = '',
			cssClass = '',
			...props
		},
		ref
	) => (
		<Link to={href}>
			<button
				ref={ref}
				disabled={disabled}
				type={type}
				className={clear(`
				${classes.base}
				${classes.size[size]}
				${classes.variant[variant]}
				${disabled && classes.disabled}
				${cssClass}
			`)}
				{...props}
			>
				{children}
			</button>
		</Link>
	)
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
	variant: PropTypes.oneOf(['primary', 'secondary', 'danger']),
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	disabled: PropTypes.bool,
	href: PropTypes.string,
	cssClass: PropTypes.string
};

export default Button;
