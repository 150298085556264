/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import Logo from '../../assets/images/logo192.png';

export default function Navigation() {
    return (
        <div className="relative bg-tertiary-500 shadow z-10">
            <div
                className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                <a href="/" className="text-lg font-medium text-white">
                    <span className="font-bold text-primary-600">André </span><span
                    className="font-bold text-white">Sanchez</span>
                </a>
            </div>
        </div>
    )

}
