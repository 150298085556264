import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Dialog, Transition} from "@headlessui/react";

const ImprintModalContext = React.createContext()
const ImprintModal = ({modal, unSetImprintModal}) => {
    useEffect(() => {
        const bind = e => {
            if (e.keyCode !== 27) {
                return
            }

            if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
                return
            }

            unSetImprintModal()
        }

        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
    }, [modal, unSetImprintModal])

    return (
        <Transition.Root show={modal} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={unSetImprintModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Impressum
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div className='impressum'><p>Angaben gemäß § 5 TMG</p>
                                            <p>Andre Sanchez <br/>
                                                Zschokkestraße 72<br/>
                                                80686 München <br/>
                                            </p>
                                            <p><strong>Vertreten durch: </strong><br/>
                                                Andre Sanchez<br/>
                                            </p>
                                            <p><strong>Kontakt:</strong> <br/>
                                                E-Mail: <a href='mailto:mail@asanchez.de'>mail@asanchez.de</a></p>
                                            <br/><br/>
                                            <p><strong>Haftung für
                                                Inhalte</strong><br/><br/>
                                                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
                                                Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
                                                jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
                                                Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
                                                Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                                                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                                                gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                                                forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                                                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                                                Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                                                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                                                Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                                                entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                                                entfernen.<br/><br/><strong>Haftung für Links</strong><br/><br/>
                                                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
                                                Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
                                                Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
                                                Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                                                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                                                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                                                waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                                                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                                                von Rechtsverletzungen werden wir derartige Links umgehend
                                                entfernen.<br/><br/><strong>Urheberrecht</strong><br/><br/>
                                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                                                Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                                                Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                                                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                                                jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
                                                sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                                                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                                                werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                                                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                                                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
                                                wir derartige Inhalte umgehend
                                                entfernen.<br/><br/><strong>Datenschutz</strong><br/><br/>
                                                Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                                                personenbezogener Daten möglich. Soweit auf unseren Seiten
                                                personenbezogene Daten (beispielsweise Name, Anschrift oder
                                                eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
                                                freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
                                                Zustimmung nicht an Dritte weitergegeben. <br/>
                                                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
                                                der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                                                lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                                                möglich. <br/>
                                                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                                                Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                                                angeforderter Werbung und Informationsmaterialien wird hiermit
                                                ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
                                                ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
                                                Werbeinformationen, etwa durch Spam-Mails, vor.<br/>
                                                <br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => unSetImprintModal(false)}
                                >
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
const ImprintModalProvider = props => {
    const [modal, setImprintModal] = useState(false)
    const unSetImprintModal = useCallback(() => {
        setImprintModal()
    }, [setImprintModal])


    return (
        <ImprintModalContext.Provider value={{unSetImprintModal, setImprintModal}} {...props} >
            {props.children}
            {modal && <ImprintModal modal={modal} unSetImprintModal={unSetImprintModal}/>}
        </ImprintModalContext.Provider>
    )
}

const useImprintModal = () => {
    const context = React.useContext(ImprintModalContext)
    if (context === undefined) {
        throw new Error('useModal must be used within a UserProvider')
    }

    return context
}

export
{
    ImprintModalProvider, useImprintModal
}