import React from "react";
import {BriefcaseIcon, CursorClickIcon, HeartIcon} from "@heroicons/react/solid";

const features = [
    {
        name: 'Medizin-Informatik',
        description:
            'Einrichtung Ihrer digitalen Praxis - Online Sprechstunden und Homeoffice, eAU, Praxisverwaltungsysteme, Telematikinfrastruktur, uvm...',
        icon: HeartIcon,
    },
    {
        name: 'Unternehmens-IT',
        description:
            'Komplettlösungen für Unternehmen - Webseite, Google Repräsentation,  Mailaccount, Geschäftsnummer, Visitenkarten, Hardware-Beratung und Office Programme als Grundausstattung ',
        icon: BriefcaseIcon,
    },
    {
        name: 'Frontend Entwicklung',
        description:
            'Ihr User Interface als Aushängeschild - Wenn die Grenzen der User Experience über das Design hinaus gehen sollen. Das Beste aus Ihrem UI herauszuholen ist mein Spezialgebiet.',
        icon: CursorClickIcon,
    },
]

export default function FeatureSection() {
    return (
        <section className="py-12 bg-tertiary-500" id="features">
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="lg:text-center">
                    <h2 className="text-lg text-white font-semibold tracking-wide uppercase">Dienstleistungen</h2>
                </div>
                <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 mt-10">
                    {features.map((feature) => (
                        <div key={feature.name}>
                            <dt className="flex items-center">
                                <div
                                    className="flex items-center justify-center h-12 w-12 text-primary-500">
                                    <feature.icon className="h-6 w-6" aria-hidden="true"/>
                                </div>
                                <p className="text-lg leading-6 font-medium text-primary-500">{feature.name}</p>
                            </dt>
                            <dd className="mt-2 text-base text-white">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </section>
    )
}