import React, {useState} from 'react';
import Steps from "./steps";
import HeroWelcomeImage from '../../../assets/images/hero-welcome.png';
import Button from "../button";
import {useContactModal} from "../modals/contact-context";

const LandingHero = () => {
    const { setContactModal } = useContactModal()


    return (
        <section className="max-w-7xl mx-auto relative bg-tertiary-500"> {/*max-w-7xl pb-20 lg:pb-48 mx-auto min-h-semi-screen*/}
            <div className="relative sm:overflow-hidden px-4 pt-16 pb-24 sm:px-6 sm:pt-24 sm:pb-36 lg:pt-32 lg:px-8 lg:pb-48">
                <div className="absolute top-0 right-0 bottom-0 w-1/2">
                    <img
                        className="h-full w-full object-cover object-left"
                        src={HeroWelcomeImage}
                        alt="Doctor Image"
                    />
                </div>
                <div className="relative">
                    <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                        <span className="block text-white">Ihre Digitalisierung</span>
                        <span className="block text-primary-500">Ihr Partner</span>
                    </h1>
                    <p className="mt-6 max-w-lg text-xl text-white sm:max-w-3xl" style={{textShadow: '1px 1px #001d3d'}}>
                        Speziell auf Sie zugeschnittene Beratung und IT-Lösungen. <br/>
                        Unabhängig von Ihrem Budget und technischen Vorkenntnissen.
                    </p>
                </div>
            </div>
            {/* Overlapping */}
            <div className="-mt-20 max-w-7xl mx-auto absolute w-full z-10 px-4 sm:px-6 lg:px-8">
                <div className="text-center shadow-lg bg-tertiary-700 bg-opacity-95 px-5 py-5 rounded-xl">
                    <Steps />
                    <div className="sm:flex sm:justify-center">
                            <div className="grid grid-cols-1 gap-3 ">
                                    <Button
                                        key="name"
                                        value="value"
                                        variant="primary"
                                        disabled= {false}
                                        onClick={() => {setContactModal(true)}}
                                    >
                                        Kontakt aufnehmen
                                    </Button>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingHero;
